import React, { useEffect } from "react";

import { Downgraded, useState as useHookState } from "@hookstate/core";

import Layout from "../../../Components/Layout";
import EditParkingArea from "../../../Components/AddParkingArea";
import AppState from "../../../States/AppState";
import AreaRoles from "../../../States/AreaRoles";
import { navigate } from "gatsby";
import { RoleLogin } from "../../../utils/Auth";

interface Props {
  type: "PARKING" | "CHARGING" | "SOCIETY";
  id: string;
}

const EditParkingAreaPage = ({ type, id }: Props) => {
  const user = useHookState(AppState).attach(Downgraded).user.get();
  const areaRoleState = useHookState(AreaRoles).get();

  useEffect(() => {
    const currentArea = areaRoleState.areas.filter(
      ({ areaId }) => areaId.split("/")[2] === id
    )[0];

    if (currentArea) {
      if (
        currentArea.role.includes("ROLE_PARTNER") &&
        currentArea.role.length === 1
      ) {
        return;
      } else if (user) {
        navigate(RoleLogin(user));
      }
    }
  });

  return (
    <Layout showHeader isPrivate>
      <EditParkingArea type={type} areaId={id} />
    </Layout>
  );
};

export default EditParkingAreaPage;
